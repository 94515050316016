import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HomeListCard from "../components/home/HomeListCard";
import InfoWrap from "../components/atom/InfoWrap";
import UserInfo from "../components/navigation/item/UserInfo";
import MainBtnWrap from "../components/navigation/item/MainBtnWrap";
import { NavLink } from "react-router-dom";
import { useModal } from "../hooks/useModal";
import DStep01Modal from "../base-components/modal-components/Diary/DStep01Modal";
import fetchService from "../util/fetchService";
import moment from "moment";
import StandardCalendarHome from "../components/molecules/calendar/StandardCalendarHome";
import ConfirmAlert from "../base-components/modal-components/ConfirmAlert";
import { useNavigate } from "react-router-dom"; // 페이지 이동을 위해 useNavigate 사용

const HomeWrap = styled.div`
  width: 100vw;
  padding: 40px 30px;
`;

const HomeListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  p {
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: #1f319d;
  }
`;
const dummyData = [
  {
    no: 41377,
    date: new Date(),
    company: "주식회사 대금지웰",
    regionFirst: "인천",
    regionSecond: "미추홀구",
    site: "반도체 클러스터 일반산업단지 조성사업 2공구",
  },
  {
    no: 41375,
    date: new Date(),
    company: "주식회사 대금지웰",
    regionFirst: "인천",
    regionSecond: "미추홀구",
    site: "반도체 클러스터 일반산업단지 조성사업 2공구",
  },
  {
    no: 41379,
    date: new Date(),
    company: "주식회사 대금지웰",
    regionFirst: "인천",
    regionSecond: "미추홀구",
    site: "반도체 클러스터 일반산업단지 조성사업 2공구",
  },
];
const Home = () => {
  // 고객접수 목록 가져오기
  const [list, setList] = useState([]);
  const [param, setParam] = useState({});
  const [selectedDate, setSelectedDate] = useState(null); // 선택한 날짜 저장
  const [showModal, setShowModal] = useState(false); // 모달 창 상태 관리
  const navigate = useNavigate(); // 페이지 이동을 위해 useNavigate 사용

  const receiptList = () => {
    fetchService("/receipt/list", "post", {
      searchword: "",
      pageSize: "4",
      currentPage: "1",
      year: "",
      month: "",
      dtFrom: "",
      dtTo: "",
      지역: "",
      처리상태: "",
    }).then((res) => {
      setList(res?.data);
    });
  };
  useEffect(() => {
    receiptList();
  }, []);

  const handleDateSelect = (date) => {
    const newSelectedDate = moment(date).format("YYYY-MM-DD");

    // 이전에 선택한 날짜와 같은 경우에만 모달 창을 표시
    if (selectedDate && selectedDate === newSelectedDate) {
      setShowModal(true);
    } else {
      setSelectedDate(newSelectedDate); // 선택한 날짜 업데이트
    }
  };

  const handleModalSubmit = () => {
    if (selectedDate) {
      const startOfDay = moment(selectedDate)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      const endOfDay = moment(selectedDate)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      console.log("Start of Day:", startOfDay);
      console.log("End of Day:", endOfDay);

      navigate("/receipt", {
        state: {
          dtTo: startOfDay,
          dtFrom: endOfDay,
        },
      });
    }
    setShowModal(false);
  };

  const handleModalCancel = () => {
    setShowModal(false); // 모달 닫기
  };

  return (
    <>
      <InfoWrap>
        <UserInfo />
        <MainBtnWrap />
      </InfoWrap>
      <HomeWrap>
        <HomeListTitle>
          <p>고객접수 상황</p>
        </HomeListTitle>
        <StandardCalendarHome setRange={handleDateSelect} />

        {/* 날짜 중복 선택 시 모달 창 */}
        {showModal && (
          <ConfirmAlert
            client={`${moment(selectedDate).format("YYYY-MM-DD")} 접수내역을`} // 선택된 날짜를 client로 전달
            text="조회"
            submit={handleModalSubmit}
            cancel={handleModalCancel}
          />
        )}
      </HomeWrap>
    </>
  );
};

export default Home;
