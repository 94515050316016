import styled from "styled-components";
import { useRecoilState } from "recoil";
import { newReceiptAtom } from "../../recoil/receipt";
import journalAtom from "../../recoil/journalAtom";

const Li = styled.li`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  div {
    display: flex;
    justify-content: space-between;
    p {
      font-weight: normal;
      font-size: 1.2rem;
    }
    p:nth-child(2) {
      color: #777;
    }
    &:last-child {
      margin-top: 0.5rem;
    }
  }
  &:hover {
    background-color: #eff2ff;
  }
`;

const ManagerList = ({
  dataAtom,
  searchList,
  searchModal,
  setSearchModal,
  searchWord,
}) => {
  // const setNewReceipt = useSetRecoilState(newReceiptAtom)
  const [newReceipt, setNewReceipt] = useRecoilState(dataAtom);
  // 일지작성 recoil
  const [journal, setJournal] = useRecoilState(journalAtom);

  const handleChange = (key, value) => {
    setJournal({
      ...journal,
      step02: {
        ...journal.step02,
        [key]: value,
      },
    });
  };

  const updateValue = (name, code, address1, address2, manager, phone) => {
    console.log("현장코드:", code); // 현장코드 확인용 로그
    console.log("선택된 현장 담당자:", manager, phone); // 담당자 정보 확인
    // setNewReceipt 호출 후 세미콜론으로 마무리
    setNewReceipt({
      ...newReceipt,
      현장명: name,
      현장코드: code, // 현장코드를 상태에 저장
      지역: address1,
      현장주소: address2,
      담당자: manager,
      연락처: phone,
    }); // 세미콜론 추가

    // setJournal 호출
    setJournal({
      ...journal,
      step02: {
        ...journal.step02,
        담당자: manager, // 현장담당자 이름 업데이트 (managerName 대신 manager)
        휴대폰: phone, // 연락처 업데이트
      },
    });
    console.log("업데이트된 journal 상태:", journal); // journal 상태 확인

    setSearchModal({
      ...searchModal,
      flag: false,
    });
  };

  const updateSale = (item) => {
    console.log(item);
    setNewReceipt({
      ...newReceipt,
      ...item,
      담당자: item.현장담당자,
      연락처: item.휴대폰,
    });

    // setJournal 호출
    setJournal({
      ...journal,
      step02: {
        ...journal.step02,
        담당자: item.현장담당자,
        휴대폰: item.휴대폰,
      },
    });

    setSearchModal({
      ...searchModal,
      flag: false,
    });
  };
  return (
    <>
      {searchList.length > 0 ? (
        searchList.map((it, key) => {
          return (
            <Li
              key={key}
              onClick={() => {
                console.log("현장 담당자 선택됨:", it.현장담당자, it.휴대폰); // 선택된 담당자 로그
                window.location.pathname.indexOf("sale")
                  ? updateSale(it)
                  : updateValue(it.현장담당자, it.직위, it.휴대폰, it.담당자);
              }}
            >
              <div>
                <p>{it.현장담당자}</p>
                <p>{it.직위}</p>
              </div>
              <div>
                <p>{it.휴대폰}</p>
              </div>
            </Li>
          );
        })
      ) : window.location.pathname.indexOf("sale") === 1 ? null : (
        <li onClick={() => updateValue(searchWord, 0)}>
          <p>
            "{searchWord}" 가 존재하지 않습니다. "{searchWord}"
            등록하시겠습니까?
          </p>
        </li>
      )}
    </>
  );
};

export default ManagerList;
