import styled from "styled-components";
import Calendar from "react-calendar";
import moment from "moment";
import { useEffect, useState } from "react";

const StandardCalenderContainer = styled.div`
  width: 100%;
  height: 100%;

  .react-calendar {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #222;
    border-radius: 8px;
    line-height: 1.125em;
  }
  .react-calendar__navigation {
    padding: 20px;
  }

  .react-calendar__navigation__label {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    position: absolute;
    transform: scale(2);
    color: #555555;
  }

  .react-calendar__navigation__prev-button {
    right: 6rem;
  }

  .react-calendar__navigation__next-button {
    right: 3rem;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__viewContainer,
  .react-calendar__month-view {
    width: 100%;
    height: 90%;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-family: "Montserrat";
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    color: #555555;
  }

  .react-calendar__tile {
    margin: 5px 0;
    padding: 10px;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #9da2ae;
  }

  /* 요일 순서를 "일월화수목금토"로 설정 */
  .react-calendar__month-view__weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  /* 주말 스타일 */
  .react-calendar__month-view__days__day--weekend {
    color: red; /* 기본 일요일 색상 */
  }

  /* 토요일 */
  .react-calendar__month-view__days__day:nth-child(7n) {
    color: blue;
  }

  /* 일요일 */
  .react-calendar__month-view__days__day:nth-child(7n + 1) {
    color: red;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #9da2ae;
  }

  .react-calendar__tile--range {
    background: #eff2ff;
  }

  /* 모든 선택된 날짜 스타일을 토요일과 같은 스타일로 변경 */
  .react-calendar__tile--active {
    background: gray; /* 선택된 날짜는 모두 회색 배경 */
    color: white; /* 글자는 모두 흰색 */
  }

  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    border-radius: 10px;
    background: gray; /* 선택된 범위의 시작과 끝도 동일하게 회색 */
    color: white;
  }
`;

const SelectDateRange = styled.div`
  position: relative;
  left: 50%;
  display: inline-block;
  transform: translateX(-50%);
  padding: 10px;
  background-color: transparent;
  input {
    min-width: 130px;
    width: 45%;
    height: 35px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    background: var(--gray-l, #f6f6f6);
    color: var(--gray-d, #555);
    font-family: Montserrat;
    padding: 1rem;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.8px;
  }
`;

const StandardCalendar = ({ setRange }) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setRange(date);
  }, [date]);

  return (
    <StandardCalenderContainer>
      <Calendar
        onChange={setDate}
        formatDay={(locale, date) => moment(date).format("DD")}
        selectRange={false}
        locale={"kr"}
        calendarType="US" // 요일을 "일월화수목금토" 순으로 설정
      />
    </StandardCalenderContainer>
  );
};

export default StandardCalendar;
